<template>
  <div>
    <quill-editor
      :ref="editorRef"
      v-model="content"
      :options="editorOptions"
      @input="onEditorInput"
    />

    <b-modal :id="`image-modal-post-${editorRef}`" title="Image">
      <b-form>
        <b-form-group label="Alt Text">
          <b-form-input v-model="imageProperties.alt"></b-form-input>
        </b-form-group>
        <b-form-group label="Width">
          <b-form-input v-model="imageProperties.width" placeholder="e.g., 100px or 50%"></b-form-input>
        </b-form-group>
        <b-form-group label="Height">
          <b-form-input v-model="imageProperties.height" placeholder="e.g., 100px or auto"></b-form-input>
        </b-form-group>
        <b-form-group label="Alignment">
          <b-form-select v-model="imageProperties.alignment">
            <option value="">None</option>
            <option value="ql-align-left">Left</option>
            <option value="ql-align-center">Center</option>
            <option value="ql-align-right">Right</option>
          </b-form-select>
        </b-form-group>
        <b-button variant="primary" @click="insertImage">Insert</b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.snow.css'
import Quill from 'quill'
import { BModal, BForm, BFormGroup, BFormInput, BFormSelect, BButton } from 'bootstrap-vue'

const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)
const BlockEmbed = Quill.import('blots/block/embed')
class ImageFormat extends BlockEmbed {
  static create(value) {
    let node = super.create(value)
    node.setAttribute('src', value.src)
    node.setAttribute('alt', value.alt || '')
    node.setAttribute('width', value.width || '')
    node.setAttribute('height', value.height || '')
    node.setAttribute('class', value.class || '')
    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      class: node.getAttribute('class'),
    }
  }
}
ImageFormat.blotName = 'image'
ImageFormat.tagName = 'img'
Quill.register(ImageFormat, true)

export default {
  name: 'QuillEditor',
  components: {
    quillEditor,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    editorRef: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      content: this.value,
      editorSelection:"",
      currentEditor:"",
      imageProperties: {
        src: '',
        alt: '',
        width: '',
        height: '',
        alignment: '',
      },
      editorOptions: {
        theme: 'snow',
        modules: {
          toolbar: {
            container: [
              [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ indent: '-1' }, { indent: '+1' }],
              [{ direction: 'rtl' }],
              [{ color: [] }, { background: [] }],
              [{ align: [] }],
              ['link', 'image', 'video'],
              [{ size: SizeStyle.whitelist }],
              ['bold', 'italic', 'underline', 'strike'],
              ['clean'],
              ['undo', 'redo'],
              ['paragraph'],
            ],
            handlers: {
              image: () => this.imageHandler(),
              link: () => this.linkHandler(),
            },
          },
        },
      },
    }
  },
  watch: {
    value(newValue) {
      this.content = newValue
    },
  },
  methods: {
    onEditorInput(value) {
      this.$emit('input', value)
    },
    imageHandler() {
        this.currentEditor =this.$refs[this.editorRef].quill
         this. editorSelection =   this.currentEditor.getSelection()
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')
      input.click()
      input.onchange = async () => {
        const file = input.files[0]
        const formData = new FormData()
        formData.append('image', file)
        try {
          const token = localStorage.getItem('token')
          const xhr = new XMLHttpRequest()
          xhr.withCredentials = false
          xhr.open('POST', `${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`)
          xhr.setRequestHeader('Authorization', `Bearer ${token}`)
          xhr.onload = () => {
            if (xhr.status === 403) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }
            if (xhr.status < 200 || xhr.status >= 300) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }
            const json = JSON.parse(xhr.responseText)
            if (!json || typeof json.location !== 'string') {
              console.error(`Invalid JSON: ${xhr.responseText}`)
              return
            }
            this.imageProperties.src = json.location
            this.$bvModal.show(`image-modal-post-${this.editorRef}`)
          }
          xhr.onerror = () => {
            console.error(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
          }
          xhr.send(formData)
        } catch (error) {
          console.error('Image upload failed:', error)
        }
      }
    },
    insertImage() {
       console.log(this.editorRef)
        console.log( this.$refs[this.editorRef].quill)
      const editor = this.$refs[this.editorRef].quill
      const range =  this. editorSelection 
      const { src, alt, width, height, alignment } = this.imageProperties
      console.log(range)
      if (range) {
          console.log(this.imageProperties)
        editor.insertEmbed(range.index, 'image', { src, alt, width, height, class: alignment })
        this.$bvModal.hide(`image-modal-post-${this.editorRef}`)
        this.resetImageProperties()
      }
    },
    resetImageProperties() {
      this.imageProperties = {
        src: '',
        alt: '',
        width: '',
        height: '',
        alignment: '',
      }
    },
    linkHandler() {
      const range = this.$refs[this.editorRef].quill.getSelection()
      if (range) {
        const url = prompt('Enter the URL')
        if (url) {
          const target = confirm('Open in new tab?') ? '_blank' : '_self'
          const linkValue = `<a href="${url}" target="${target}">${this.$refs[this.editorRef].quill.getText(range)}</a>`
          this.$refs[this.editorRef].quill.clipboard.dangerouslyPasteHTML(range.index, linkValue)
        }
      }
    },
  },
}
</script>

<style scoped>
.quill-editor {
  max-height: 400px; /* Limit height to prevent overflow */
  overflow-y: auto; /* Add scrollbar if content overflows */
}

.ql-toolbar.ql-snow {
  z-index: 10; /* Ensure toolbar is above other elements */
}

.ql-container.ql-snow {
  z-index: 9; /* Ensure container is below the toolbar */
}
.ql-align-left {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}
.ql-align-right {
  float: right;
  margin-left: 10px;
  margin-bottom: 10px;
}
.ql-align-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
 .modal-footer {
   display: none !important;
}
</style>
